import "../App.css";
import "swiper/css";
import "swiper/css/pagination";
import { useRef, useState } from "react";
import Header from "../components/Header";
import BottomTabBarMenu from "../components/BottomTabBarMenu";
import { useStateValue } from "../StateProvider";
import { mobileCheck } from "../functions";
import DesktopLanguageButton from "../components/DesktopLanguageButton";
import {
  InfoIcon,
  TabIcon1,
  TabIcon2,
  TabIcon3,
  TabIcon4,
  TabIcon6,
} from "../assets/images";
import FeatureLists from "../components/FeatureLists";
import { Tooltip } from "@mui/material";

function Intro({ animationLoaded = false }) {
  const [enableDescription] = useState(false);
  const [{ translation, isInsideHotspotView }] = useStateValue();
  const iframeRef = useRef(null);
  const selectedView = "Exterior";
  const isHotspotEnabled = true;
  const loading = false;
  const isEnableLangButton = true;
  const hotspotOption = null;
  const isFeaturesEnabled = false;

  return (
    <>
      <div className="flex absolute z-[10000]">
        <div className="App w-9/12 flex flex-1">
          <div className="flex items-center justify-center h-[100dvh] w-screen">
            <div className="flex flex-col bg-transparent h-[100dvh] w-screen rounded-[5px]">
              <Header isIntro={true} animationLoaded={animationLoaded} />
              <div className={`${"h-[78%]"} sm:h-[100%]`}>
                <div
                  className={`absolute w-[100%] h-[100dvh] bg-[rgba(0,0,0,0.75)] top-0 ${
                    !animationLoaded && "z-[1]"
                  }`}
                ></div>
              </div>
              {!isFeaturesEnabled &&
              hotspotOption === null &&
              enableDescription ? (
                <div className="p-4 bg-[#fff] sm:hidden border-b-[1px] border-[#54555A]">
                  <p className="text-[14px] text-[#E1251B] font-bold text-left">
                    {translation.descriptionTitle}
                  </p>
                </div>
              ) : null}
              {isFeaturesEnabled && (
                <FeatureLists
                  menu={[]}
                  iframeRef={iframeRef}
                  selectedView={selectedView}
                  setselectedView={() => {}}
                />
              )}
              <div className="w-full h-[100%]"></div>
              <BottomTabBarMenu
                animationLoaded={animationLoaded}
                isIntro={true}
                enabled={true}
                openMenu={() => null}
                tabs={
                  mobileCheck()
                    ? [
                        {
                          image: <TabIcon1 />,
                          enableRightArrow: false,
                          id: "intext",
                          xflow: "left",
                          onclick: () => {},
                          selected: selectedView,
                          menu: [],
                          text: translation.viewText,
                        },
                        {
                          image: <TabIcon2 />,
                          enableRightArrow: false,
                          enabled: isHotspotEnabled,
                          onclick: () => {},
                          menu: [],
                          text: translation.hotspotText,
                        },
                        {
                          image: <TabIcon3 />,
                          enableRightArrow: false,
                          onclick: () => {},
                          id: "hotspotmenu",
                          menu: [],
                          hotspotOption: hotspotOption,
                          selected: isFeaturesEnabled,
                          text: translation.featuresText,
                        },
                        {
                          image: <TabIcon4 />,
                          enableRightArrow: true,
                          id: "support",
                          onclick: () => {},
                          menu: [],
                          text: translation.contactText,
                        },
                      ]
                    : [
                        {
                          image: <TabIcon2 />,
                          enableRightArrow: false,
                          xflow: "left",
                          enabled: false,
                          onclick: () => {},
                          menu: [],
                          text: translation.hotspotText,
                        },
                        {
                          image: <TabIcon1 />,
                          enableRightArrow: false,
                          onclick: () => {},
                          selected: selectedView,
                          id: "rightMenu",
                          menu: [],
                          text: translation.viewText,
                        },
                        {
                          image: <TabIcon6 />,
                          enableRightArrow: false,
                          onclick: () => {},
                          menu: [],
                          text: translation.fullScreenText,
                        },
                      ]
                }
              />
            </div>
          </div>
        </div>
        {!mobileCheck() && animationLoaded && (
          <Tooltip
            placement="top"
            title={translation.productHighlightsText}
            arrow
            open={true}
          >
            <div
              className={`opacity-60 p-1 hidden sm:flex sm:absolute left-[20px] bottom-[15px] w-[40px] z-[10] h-[40px] bg-[#E1251B] cursor-pointer justify-center items-center rounded-[10px] opacity-100`}
            >
              <div className="animate-zoom">
                <InfoIcon />
              </div>
            </div>
          </Tooltip>
        )}

        {!mobileCheck() && !loading && animationLoaded && (
          <DesktopLanguageButton
            isSidebarEnabled={true}
            isEnableLangButton={isEnableLangButton}
            menu={[]}
            isIntro={true}
          />
        )}
      </div>
    </>
  );
}

export default Intro;
