import React from "react";

const CloseIcon = ({size, color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width={size}
      height={size}
    >
      <line
        fill="none"
        stroke={color}
        stroke-width="4"
        stroke-miterlimit="10"
        x1="7.741"
        y1="7.741"
        x2="42.359"
        y2="42.359"
      />
      <line
        fill="none"
        stroke={color}
        stroke-width="4"
        stroke-miterlimit="10"
        x1="42.258"
        y1="7.742"
        x2="7.618"
        y2="42.382"
      />
    </svg>
  );
};

export default CloseIcon;
