import React, { useState } from "react";
import "../App.css";
import { mobileCheck } from "../functions";
import { Popover } from "@headlessui/react";
import { useStateValue } from "../StateProvider";
import { Tooltip } from "@mui/material";

const BottomTabBarItem = ({ tab, index, isIntro = false, animationLoaded }) => {
  const [{ hotspotViewSelected, lang }, dispatch] = useStateValue();
  if (isIntro) {
    return (
      <Tooltip
        placement={mobileCheck() ? "top" : "right"}
        title={tab.text}
        arrow
        open={mobileCheck() ? animationLoaded : true}
      >
        <div
          className={`mx-2 w-[60px] h-[60px] rounded-full cursor-pointer sm:mb-4`}
        >
          <div
            key={index}
            id={tab.id}
            onClick={() => {
              // tab.onclick();
            }}
            className={`menu flex items-center justify-center ${
              mobileCheck()
                ? index === 0
                  ? tab.isInsideHotspotView
                    ? "p-1"
                    : "p-3"
                  : "p-3"
                : index === 1
                ? "p-0"
                : ""
            } w-[60px] h-[60px] ${
              tab.enabled === true ? "bg-[#D52B1E] enabledButton" : "bg-white"
            } ${isIntro === false && 'sm:hover:bg-[#D52B1E]'} rounded-full cursor-pointer sm:mb-4 sm:first:mt-2 ${
              index === 3 ? "sm:p-6" : ""
            }`}
          >
            {tab.image}
          </div>
          {tab.menu && tab.menu.length > 0 && (
            <div className={`menu-content`} onClick={() => {}}>
              {tab.menu.map((men, mIndex) => {
                return (
                  <div
                    key={mIndex}
                    onClick={() => {
                      men.onclick();
                    }}
                    className={`menu-row first:rounded-t-[10px] last:rounded-b-[10px] only:rounded-[10px] py-2 ${isIntro === false && 'hover:bg-[#D52B1E] hover:text-white'} flex justify-between items-center px-4 ${
                      tab.selected === men.text || men.selected === true
                        ? "bg-[#D52B1E]"
                        : ""
                    }`}
                  >
                    <p
                      className={`text-[14px] italic ${
                        tab.selected === men.text || men.selected === true
                          ? "text-[#fff]"
                          : "text-black"
                      }`}
                    >
                      {men.text}
                    </p>
                    {tab.enableRightArrow && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#fff"
                        class="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Tooltip>
    );
  }
  if (tab.menu && tab.menu.length > 0) {
    return (
      <div
        className={`mx-2 w-[60px] h-[60px] rounded-full cursor-pointer sm:mb-4`}
      >
        <Popover>
          {({ open, close }) => {
            return (
              <div className="flex items-center justify-center">
                <Popover.Button
                  onClick={() => {
                    // tab.onclick();
                  }}
                >
                  <div
                    key={index}
                    id={tab.id}
                    onClick={() => {
                      tab.onclick();
                    }}
                    className={`menu flex items-center justify-center ${
                      mobileCheck()
                        ? index === 0
                          ? tab.isInsideHotspotView
                            ? "p-1"
                            : "p-3"
                          : "p-3"
                        : index === 1
                        ? "p-0"
                        : ""
                    } mx-2 w-[60px] h-[60px] ${
                      open
                        ? "bg-[#D52B1E] enabledButton"
                        : mobileCheck()
                        ? "disabledButton"
                        : ""
                    } sm:hover:bg-[#D52B1E] rounded-full cursor-pointer sm:mb-4 sm:first:mt-2 ${
                      index === 3 ? "sm:p-6" : ""
                    }`}
                  >
                    {tab.image}
                  </div>
                </Popover.Button>
                {tab.menu && tab.menu.length > 0 && (
                  <Popover.Panel
                    className={`absolute z-10 ${
                      mobileCheck()
                        ? index === 0
                          ? "bottom-[80px] left-[10px]"
                          : "bottom-[80px] right-[20px]"
                        : "left-[90px]"
                    } max-w-[60%]`}
                  >
                    <div className={`menu-content`}>
                      {tab.menu.map((men, mIndex) => {
                        return (
                          <div
                            key={mIndex}
                            onClick={() => {
                              men.onclick();
                              close();
                            }}
                            className={`menu-row first:rounded-t-[10px] last:rounded-b-[10px] only:rounded-[10px] py-2 hover:bg-[#D52B1E] hover:text-white flex justify-between items-center px-4 ${
                              tab.selected === men.text || men.selected === true
                                ? "bg-[#D52B1E]"
                                : ""
                            }`}
                          >
                            <p
                              className={`text-[14px] italic ${
                                tab.selected === men.text ||
                                men.selected === true
                                  ? "text-[#fff]"
                                  : "text-black"
                              }`}
                            >
                              {men.text}
                            </p>
                            {tab.enableRightArrow && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#fff"
                                class="bi bi-chevron-right"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Popover.Panel>
                )}
              </div>
            );
          }}
        </Popover>
      </div>
    );
  }
  return (
    <div
      className={`mx-2 w-[60px] h-[60px] rounded-full cursor-pointer sm:mb-4`}
    >
      <div
        key={index}
        id={tab.id}
        onClick={() => {
          tab.onclick();
        }}
        className={`menu flex items-center justify-center ${
          mobileCheck()
            ? index === 0
              ? tab.isInsideHotspotView
                ? "p-1"
                : "p-3"
              : "p-3"
            : index === 1
            ? "p-0"
            : ""
        } w-[60px] h-[60px] ${
          tab.enabled === true ? "bg-[#D52B1E] enabledButton" : "bg-white"
        } sm:hover:bg-[#D52B1E] rounded-full cursor-pointer sm:mb-4 sm:first:mt-2 ${
          index === 3 ? "sm:p-6" : ""
        }`}
      >
        {tab.image}
      </div>
      {tab.menu && tab.menu.length > 0 && (
        <div className={`menu-content`} onClick={() => {}}>
          {tab.menu.map((men, mIndex) => {
            return (
              <div
                key={mIndex}
                onClick={() => {
                  men.onclick();
                }}
                className={`menu-row first:rounded-t-[10px] last:rounded-b-[10px] only:rounded-[10px] py-2 hover:bg-[#D52B1E] hover:text-white flex justify-between items-center px-4 ${
                  tab.selected === men.text || men.selected === true
                    ? "bg-[#D52B1E]"
                    : ""
                }`}
              >
                <p
                  className={`text-[14px] italic ${
                    tab.selected === men.text || men.selected === true
                      ? "text-[#fff]"
                      : "text-black"
                  }`}
                >
                  {men.text}
                </p>
                {tab.enableRightArrow && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#fff"
                    class="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BottomTabBarItem;
