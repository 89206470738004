// GoogleAnalytics.js
import { useEffect } from "react";

const useGoogleAnalytics = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-G6E8GXX71B`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-G6E8GXX71B");
    };
  }, []);
};

export const sendScreenVisitEvent = (screenName) => {
  const eventData = {
    event_category: "screen_visit",
    event_action: "view",
    event_label: screenName,
  };

  window.gtag("event", "custom", eventData);
};

export const sendButtonClickEvent = (buttonName, customParams = {}) => {
  const eventData = {
    event_category: "button",
    event_action: "click",
    event_label: buttonName,
    ...customParams,
  };

  window.gtag("event", "custom", eventData);
};

export default useGoogleAnalytics;
