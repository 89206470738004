import React from "react";
import { useStateValue } from "../StateProvider";
import { actionTypes } from "../reducer";
import { mobileCheck } from "../functions";
import { Popover } from "@headlessui/react";
import { irLogo, irLogoMobile } from "../assets/images";
import { Tooltip } from "@mui/material";
import { sendButtonClickEvent } from "../GoogleAnalytics";

const Header = ({ isIntro = false, animationLoaded }) => {
  const [{ lang, translation }, dispatch] = useStateValue();
  const menu = [
    {
      label: "English",
      key: "en",
    },
    {
      label: "Portuguese",
      key: "pt",
    },
    {
      label: "Spanish",
      key: "es",
    },
  ];
  return (
    <>
      <div className="p-4 py-2 flex items-center sm:absolute sm:hidden sm:bg-white sm:z-[10]">
        <img
          src={mobileCheck() ? irLogoMobile : irLogo}
          className="sm:w-[150px] w-[60px]"
        />
      </div>

      <div
        className={`sm:hidden absolute mt-2 right-4 ${
          animationLoaded && isIntro ? "z-[10001]" : ""
        }`}
      >
        <div className="menu flex items-center justify-center w-[40px] h-[40px] bg-[#D52B1E] sm:bg-[#717174] hover:bg-[#D52B1E] rounded-full cursor-pointer relative mt-1">
          <Popover>
            {({ open, close }) => {
              return (
                <>
                  <Popover.Button
                    onClick={() => {
                      sendButtonClickEvent("language_button");
                    }}
                  >
                    {isIntro && mobileCheck() ? (
                      <Tooltip
                        placement="bottom"
                        title={translation.languageText}
                        arrow
                        open={mobileCheck() ? animationLoaded : true}
                      >
                        <p
                          className={`text-white text-[13px] uppercase font-bold ${
                            animationLoaded && "z-[2]"
                          } absolute left-0 bottom-0 right-0 top-[10px]`}
                        >
                          {lang.key}
                        </p>
                        {animationLoaded && (
                          <span class="animate-ping ping-count absolute inline-flex h-full w-full rounded-full bg-[#D52B1E] opacity-30 z-[1] top-0 left-0"></span>
                        )}
                      </Tooltip>
                    ) : (
                      <>
                        <p
                          className={`text-white text-[13px] uppercase font-bold ${
                            animationLoaded && "z-[2]"
                          } absolute left-0 bottom-0 right-0 top-[10px]`}
                        >
                          {lang.key}
                        </p>
                        {animationLoaded && (
                          <span class="animate-ping ping-count absolute inline-flex h-full w-full rounded-full bg-[#D52B1E] opacity-30 z-[1] top-0 left-0"></span>
                        )}
                      </>
                    )}
                  </Popover.Button>
                  <Popover.Panel className={`absolute z-10 right-0 top-11`}>
                    {!isIntro && menu.length > 0 && (
                      <div className={`menu-content right-0 top-[100%]`}>
                        {menu.map((men, mIndex) => {
                          let isSelected = lang.key === men.key;
                          return (
                            <div
                              key={mIndex}
                              onClick={() => {
                                sendButtonClickEvent("language_element", {
                                  element: men.key,
                                });
                                dispatch({
                                  type: actionTypes.SET_LANGUAGE,
                                  lang: men,
                                });
                                close();
                              }}
                              className={`menu-row py-2 hover:bg-[#D52B1E] hover:text-white flex justify-between items-center px-4 ${
                                isSelected ? "bg-[#D52B1E]" : "bg-white"
                              } ${
                                mIndex === 0
                                  ? "rounded-t-[10px]"
                                  : menu.length - 1 === mIndex
                                  ? "rounded-b-[10px]"
                                  : ""
                              }`}
                            >
                              <p
                                className={`italic text-[14px] ${
                                  isSelected ? "text-white" : "text-black"
                                }`}
                              >
                                {men.label}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Popover.Panel>
                </>
              );
            }}
          </Popover>
        </div>
      </div>
    </>
  );
};

export default Header;
