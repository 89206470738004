import "../App.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import BottomTabBarMenu from "../components/BottomTabBarMenu";
import { useStateValue } from "../StateProvider";
import SideMenu from "../components/SideMenu";
import { actionTypes } from "../reducer";
import { mobileCheck } from "../functions";
import ProductHighlights from "../components/ProductHighlights";
import DesktopLanguageButton from "../components/DesktopLanguageButton";
import {
  BackIconNew,
  ExitViewIcon,
  ExitViewIconWhite,
  InfoIcon,
  IntroImage,
  MenuIcon,
  NextHotspotButton,
  PrevHotspotButton,
  TabIcon1,
  TabIcon2,
  TabIcon3,
  TabIcon4,
  TabIcon6,
  irLogo,
} from "../assets/images";
import HighlightModal from "../components/HighlightModal";
import FeatureLists from "../components/FeatureLists";
import Intro from "./intro";
import { useLocation } from "react-router-dom";
import { sendButtonClickEvent, sendScreenVisitEvent } from "../GoogleAnalytics";

function Home() {
  const iframeSrc =
    "https://thriving-crumble-fe1fa1.netlify.app/?show_buttons=false";
  const [enableDescription, setenableDescription] = useState(false);
  const [enablemodal, setenablemodal] = useState(false);
  const [modalContent, setmodalContent] = useState(null);
  const [isSidebarEnabled, setIsSidebarEnabled] = useState(false);
  const [isEnabledBottomTabs, setisEnabledBottomTabs] = useState(false);
  const [{ lang, translation, isInsideHotspotView }, dispatch] =
    useStateValue();
  const iframeRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedView, setselectedView] = useState("Exterior");
  const [isHotspotEnabled, setIsHotspotEnabled] = useState(true);
  const [isHighlightEnabled, setisHighlightEnabled] = useState(true);
  const [clickedHotspot, setclickedHotspot] = useState("");
  const [loading, setloading] = useState(true);
  const [scenes, setscenes] = useState({});
  const [isInfoButtonClicked, setisInfoButtonClicked] = useState(false);
  const [animationLoaded, setanimationLoaded] = useState(false);
  const [isEnableLangButton, setisEnableLangButton] = useState(true);
  const [changeSideMenuView, setchangeSideMenuView] = useState(false);
  const [hotspotOption, sethotspotOption] = useState(null);
  const [swiperIndex, setswiperIndex] = useState(0);
  const [isFeaturesEnabled, setisFeaturesEnabled] = useState(false);
  const [isIntroEnabled, setisIntroEnabled] = useState(true);
  const location = useLocation();
  const [queryParam, setQueryParam] = useState("");
  const menu = [
    {
      label: "English",
      key: "en",
    },
    {
      label: "Portuguese",
      key: "pt",
    },
    {
      label: "Spanish",
      key: "es",
    },
  ];

  const sendMessageToIframe = (func) => {
    iframeRef.current.contentWindow.postMessage({ func }, "*");
  };
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      enterFullScreen();
    } else {
      exitFullScreen();
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get("lang");
    setQueryParam(paramValue);
    if (paramValue) {
      let pValue = paramValue.toLowerCase();
      sendScreenVisitEvent("home_screen", { language: pValue });
      if (pValue === "en") {
        dispatch({
          type: actionTypes.SET_LANGUAGE,
          lang: {
            label: "English",
            key: "en",
          },
        });
        dispatch({
          type: actionTypes.SET_NO_LANGUAGE_CHANGE,
          value: true,
        });
      }
      if (pValue === "pt") {
        dispatch({
          type: actionTypes.SET_LANGUAGE,
          lang: {
            label: "Portuguese",
            key: "pt",
          },
        });
        dispatch({
          type: actionTypes.SET_NO_LANGUAGE_CHANGE,
          value: true,
        });
      }
      if (pValue === "es") {
        dispatch({
          type: actionTypes.SET_LANGUAGE,
          lang: {
            label: "Spanish",
            key: "es",
          },
        });
        dispatch({
          type: actionTypes.SET_NO_LANGUAGE_CHANGE,
          value: true,
        });
      }
    } else {
      sendScreenVisitEvent("home_screen", { language: "en" });
      dispatch({
        type: actionTypes.SET_LANGUAGE,
        lang: {
          label: "English",
          key: "en",
        },
      });
    }
  }, [location.search]);

  const enterFullScreen = () => {
    const docElm = document.documentElement;

    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      // Firefox
      docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      docElm.webkitRequestFullscreen();
    } else if (docElm.msRequestFullscreen) {
      // IE/Edge
      docElm.msRequestFullscreen();
    }

    setIsFullScreen(true);
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      // Chrome, Safari and Opera
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      // IE/Edge
      document.msExitFullscreen();
    }

    setIsFullScreen(false);
  };

  const closeSideMenu = () => {
    if (isSidebarEnabled === true) {
      // iframeRef.current.contentWindow.postMessage(
      //   { func: "ExitHotspotView" },
      //   "*"
      // );
      // iframeRef.current.contentWindow.postMessage(
      //   { func: "MoveToExterior" },
      //   "*"
      // );
      // setselectedView("Exterior");
    }

    setIsSidebarEnabled(!isSidebarEnabled);
  };

  useEffect(() => {
    const receiveMessage = (event) => {
      if (event.source === iframeRef.current.contentWindow) {
        const logData = event.data;
        console.log("Received log:", logData);
        if (logData.type === "loadingcomplete") {
          setscenes(JSON.parse(logData.content));
          // setTimeout(() => {
          setloading(false);
          // }, 2000);
        }
        if (logData.type === "hotspotClicked3d") {
          console.log("$$hotspotClicked3d", logData.content);
          setclickedHotspot(logData.content);
          sethotspotOption(logData.content);
          setIsSidebarEnabled(true);
          setisFeaturesEnabled(false);
          dispatch({
            type: actionTypes.SET_INSIDE_VIEW,
            value: true,
            id: logData.content,
          });
          iframeRef.current.contentWindow.postMessage(
            { func: "DisableAllHotspots" },
            "*"
          );
          setIsHotspotEnabled(false);
          let index = hotspots.findIndex(
            (element) => element.id === hotspotOption
          );
          sendButtonClickEvent("hotspot_element", {
            element: hotspots[index].languages[0].heading,
            from: "3d",
          });
        }
        if (logData.func === "IntroAnimationCompleted") {
          setisEnabledBottomTabs(true);
          setIsSidebarEnabled(true);
          setanimationLoaded(true);
        }
      }
    };
    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, [clickedHotspot]);

  useEffect(() => {
    if (mobileCheck() === false && isEnabledBottomTabs === true) {
      setTimeout(() => {
        setisEnabledBottomTabs(false);
        const rightMenu = document.getElementById("rightMenu");
        if (rightMenu) {
          const rightMenuContent = rightMenu.querySelector(".menu-content");
          if (rightMenuContent && rightMenuContent.style) {
            rightMenuContent.style.display = "none";
          }
        }
      }, 10000);
    }
  }, [isEnabledBottomTabs]);

  useEffect(() => {
    if (!loading && animationLoaded) {
      setTimeout(() => {
        setisEnableLangButton(false);
      }, 5000);
    }
  }, [animationLoaded]);

  useEffect(() => {
    if (mobileCheck()) {
      iframeRef.current.contentWindow.postMessage(
        { func: "SwitchToMobileView" },
        "*"
      );
    } else {
      iframeRef.current.contentWindow.postMessage(
        { func: "SwitchToPcView" },
        "*"
      );
    }
  }, []);

  const hotspots =
    scenes && scenes.scenes && scenes.scenes.length > 0
      ? selectedView === "Exterior"
        ? [...scenes.scenes[0].exteriorHotspots]
        : selectedView === "Interior"
        ? [...scenes.scenes[0].interiorHotspots]
        : [...scenes.scenes[0].sideHotspots]
      : [];

  let hotspot =
    hotspotOption === null
      ? null
      : hotspots.filter((h) => h.id === hotspotOption)[0];

  let index = hotspots.findIndex((element) => element.id === hotspotOption);
  const nextIndex = index >= hotspots.length - 1 ? 0 : index + 1;
  const prevIndex = index === 0 ? hotspots.length - 1 : index - 1;

  let hotspotLanguageData = hotspot
    ? hotspot.languages.filter((h) => h.langugageID.toLowerCase() === lang.key)
    : [];
  let hotspotTranslatedData =
    hotspotLanguageData.length > 0
      ? hotspotLanguageData[0]
      : hotspot && hotspot.languages
      ? hotspot.languages[0]
      : null;

  let hotspotMenu = hotspots.map((hs) => {
    let hotspotLanguageData = hs.languages.filter(
      (h) => h.langugageID.toLowerCase() === lang.key
    );
    let hotspotTranslatedData =
      hotspotLanguageData.length > 0
        ? hotspotLanguageData[0]
        : hotspot.languages
        ? hotspot.languages[0]
        : null;
    return {
      id: hs.id,
      text: hotspotTranslatedData.heading,
      heading: hotspotTranslatedData.heading,
      description: hotspotTranslatedData.description,
      onclick: () => {
        sendButtonClickEvent("hotspot_element", {
          element: hotspotTranslatedData.heading,
        });
        const isInterior =
          scenes.scenes[0].interiorHotspots.filter((ih) => ih.id === hs.id)
            .length > 0;
        const isExterior =
          scenes.scenes[0].exteriorHotspots.filter((ih) => ih.id === hs.id)
            .length > 0;
        const isSide =
          scenes.scenes[0].sideHotspots.filter((ih) => ih.id === hs.id).length >
          0;
        if (isInterior) {
          iframeRef.current.contentWindow.postMessage(
            { func: "MoveToInterior" },
            "*"
          );
          setselectedView("Interior");
        }
        if (isExterior) {
          iframeRef.current.contentWindow.postMessage(
            { func: "MoveToExterior" },
            "*"
          );
          setselectedView("Exterior");
        }
        if (isSide) {
          iframeRef.current.contentWindow.postMessage(
            { func: "MoveToSide" },
            "*"
          );
        }
        iframeRef.current.contentWindow.postMessage(
          {
            func: "HotspotClicked",
            parameter: hs.id,
          },
          "*"
        );
        sethotspotOption(hs.id);
        setisFeaturesEnabled(false);
        dispatch({
          type: actionTypes.SET_INSIDE_VIEW,
          value: true,
          id: hs.id,
        });
        // if (!isInsideHotspotView) {
        //   setTimeout(() => {
        //     if (!isHotspotEnabled) {
        //       sendMessageToIframe("DisableAllHotspots");
        //       setIsHotspotEnabled(false);
        //     } else {
        //       sendMessageToIframe("EnableAllHotspots");
        //       setIsHotspotEnabled(true);
        //     }
        //   }, 850);
        // }
      },
      selected: hotspotOption === hs.id,
    };
  });

  const reset = () => {
    setisFeaturesEnabled(false);
    sethotspotOption(null);
    // iframeRef.current.contentWindow.postMessage(
    //   { func: "ExitHotspotView" },
    //   "*"
    // );
    // dispatch({
    //   type: actionTypes.SET_INSIDE_VIEW,
    //   value: false,
    //   id: null,
    // });
  };

  const checkIfMobileAndIntroEnabled = () => {
    if (mobileCheck()) {
      return !loading && isIntroEnabled;
    } else {
      return animationLoaded && isIntroEnabled;
    }
  };

  return (
    <>
      {checkIfMobileAndIntroEnabled() && (
        <div className="w-[100%] h-[100%] absolute z-[10000] flex justify-center items-center">
          {!mobileCheck() && (
            <img
              src={IntroImage}
              className="w-[auto] h-[220px] absolute z-[10001] mb-10 rounded-[15px] border-[1px] border-[#fff] opacity-90"
            />
          )}
          {animationLoaded && (
            <div
              className={`absolute z-[10001] ${
                mobileCheck() ? "" : "mt-[300px]"
              } flex flex-col items-center`}
            >
              <p className="text-white">{translation.introText}</p>
              <div
                className="bg-[#E1251B] px-6 py-2 rounded-md mt-2 cursor-pointer buttonShadow"
                onClick={() => {
                  sendButtonClickEvent("ok_intro_button");
                  setisIntroEnabled(false);
                }}
              >
                <p className="text-white font-bold">OK</p>
              </div>
            </div>
          )}
          <Intro animationLoaded={animationLoaded} />
        </div>
      )}
      {loading && (
        <div className="absolute top-0 left-0 z-[10000] w-full h-[100dvh] flex flex-col flex-1 bg-white justify-center items-center">
          <img src={irLogo} className="sm:w-[400px] w-[300px]" />
          <p className="mt-6 sm:text-[13px] text-[9px]">{translation.name}</p>
          <div className="mt-2 section">
            <span class="loader"></span>
          </div>
        </div>
      )}
      <div className="flex">
        <div className="App w-9/12 bg-[#fff] flex flex-1">
          <div className="flex items-center justify-center h-[100dvh] w-screen">
            <div className="flex flex-col bg-[#fff] h-[100dvh] w-screen rounded-[5px]">
              {mobileCheck() && <Header />}
              <div className={`h-[45%] sm:h-[100%] relative`}>
                <iframe
                  ref={iframeRef}
                  src={iframeSrc}
                  title="Embedded Content"
                  width={
                    mobileCheck() ? "100%" : isSidebarEnabled ? "75%" : "100%"
                  }
                  height="100%"
                  className={
                    mobileCheck() &&
                    !animationLoaded &&
                    !loading &&
                    "absolute z-[10000000]"
                  }
                />
              </div>
              <div className="min-h-[36%] max-h-[36%] flex flex-col sm:hidden">
                {!isFeaturesEnabled &&
                hotspotOption === null &&
                !enableDescription ? (
                  <div className="p-4 bg-[#fff] sm:hidden border-b-[1px] border-[#54555A]">
                    <p className="text-[14px] text-[#E1251B] font-bold text-left">
                      {translation.name}
                    </p>
                  </div>
                ) : null}
                {!isFeaturesEnabled &&
                hotspotOption === null &&
                enableDescription ? (
                  <div className="p-4 bg-[#fff] sm:hidden border-b-[1px] border-[#54555A]">
                    <p className="text-[14px] text-[#E1251B] font-bold text-left">
                      {translation.descriptionTitle}
                    </p>
                  </div>
                ) : null}
                {isFeaturesEnabled && (
                  <FeatureLists
                    menu={hotspotMenu}
                    iframeRef={iframeRef}
                    selectedView={selectedView}
                    setselectedView={setselectedView}
                    hotspotOption={hotspotOption}
                    reset={reset}
                    isHotspotEnabled={isHotspotEnabled}
                    sendMessageToIframe={sendMessageToIframe}
                    setIsHotspotEnabled={setIsHotspotEnabled}
                  />
                )}
                {hotspotOption !== null ? (
                  isFeaturesEnabled ? null : (
                    <>
                      <div className="p-4 bg-[#fff] sm:hidden border-b-[1px] border-[#54555A] px-6">
                        <p className="text-[14px] text-[#E1251B] font-bold text-left">
                          {hotspotTranslatedData &&
                            hotspotTranslatedData.heading}
                        </p>
                      </div>
                      <div className="sm:hidden z-0 overflow-y-scroll p-4 px-6 flex-1">
                        {translation.highlightContent &&
                          translation.highlightContent.map((hContent) => {
                            let hotspotLanguageData = hotspot
                              ? hotspot.languages.filter(
                                  (h) =>
                                    h.langugageID.toLowerCase() === lang.key
                                )
                              : [];
                            let hotspotTranslatedData =
                              hotspotLanguageData.length > 0
                                ? hotspotLanguageData[0]
                                : hotspot && hotspot.languages
                                ? hotspot.languages[0]
                                : null;
                            return (
                              <div className="flex flex-col flex-1 justify-center items-start sm:mb-4">
                                <p className="text-black text-[14px] text-left">
                                  {hotspotTranslatedData &&
                                    hotspotTranslatedData.description}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                      <div className="sm:hidden border-t-[2px] border-[#919194] flex justify-between px-[25px] py-3 flex justify-center items-center">
                        <div
                          className={`w-[30px] cursor-pointer`}
                          onClick={() => {
                            sendButtonClickEvent("previous_hotspot_button");
                            const isInterior =
                              scenes.scenes[0].interiorHotspots.filter(
                                (ih) => ih.id === hotspots[prevIndex].id
                              ).length > 0;
                            const isExterior =
                              scenes.scenes[0].exteriorHotspots.filter(
                                (ih) => ih.id === hotspots[prevIndex].id
                              ).length > 0;
                            const isSide =
                              scenes.scenes[0].sideHotspots.filter(
                                (ih) => ih.id === hotspots[prevIndex].id
                              ).length > 0;
                            if (isInterior) {
                              iframeRef.current.contentWindow.postMessage(
                                { func: "MoveToInterior" },
                                "*"
                              );
                              setselectedView("Interior");
                            }
                            if (isExterior) {
                              iframeRef.current.contentWindow.postMessage(
                                { func: "MoveToExterior" },
                                "*"
                              );
                              setselectedView("Exterior");
                            }
                            if (isSide) {
                              iframeRef.current.contentWindow.postMessage(
                                { func: "MoveToSide" },
                                "*"
                              );
                              setselectedView("Side");
                            }
                            iframeRef.current.contentWindow.postMessage(
                              {
                                func: "HotspotClicked",
                                parameter: hotspots[prevIndex].id,
                              },
                              "*"
                            );
                            sethotspotOption(hotspots[prevIndex].id);
                          }}
                        >
                          <PrevHotspotButton />
                        </div>
                        <div
                          className={`w-[30px] cursor-pointer`}
                          onClick={() => {
                            sendButtonClickEvent("next_hotspot_button");
                            const isInterior =
                              scenes.scenes[0].interiorHotspots.filter(
                                (ih) => ih.id === hotspots[nextIndex].id
                              ).length > 0;
                            const isExterior =
                              scenes.scenes[0].exteriorHotspots.filter(
                                (ih) => ih.id === hotspots[nextIndex].id
                              ).length > 0;
                            const isSide =
                              scenes.scenes[0].sideHotspots.filter(
                                (ih) => ih.id === hotspots[nextIndex].id
                              ).length > 0;
                            if (isInterior) {
                              iframeRef.current.contentWindow.postMessage(
                                { func: "MoveToInterior" },
                                "*"
                              );
                              setselectedView("Interior");
                            }
                            if (isExterior) {
                              iframeRef.current.contentWindow.postMessage(
                                { func: "MoveToExterior" },
                                "*"
                              );
                              setselectedView("Exterior");
                            }
                            if (isSide) {
                              iframeRef.current.contentWindow.postMessage(
                                { func: "MoveToSide" },
                                "*"
                              );
                            }
                            iframeRef.current.contentWindow.postMessage(
                              {
                                func: "HotspotClicked",
                                parameter: hotspots[nextIndex].id,
                              },
                              "*"
                            );
                            sethotspotOption(hotspots[nextIndex].id);
                            if (!isInsideHotspotView) {
                              setTimeout(() => {
                                if (!isHotspotEnabled) {
                                  sendMessageToIframe("DisableAllHotspots");
                                  setIsHotspotEnabled(false);
                                } else {
                                  sendMessageToIframe("EnableAllHotspots");
                                  setIsHotspotEnabled(true);
                                }
                              }, 850);
                            }
                          }}
                        >
                          <NextHotspotButton />
                        </div>
                        <div
                          className={`w-[30px] h-[30px] flex justify-center items-center cursor-pointer rounded-full bg-[#E1251B]`}
                          onClick={() => {
                            if (hotspotOption === null) {
                            } else {
                              // iframeRef.current.contentWindow.postMessage(
                              //   { func: "ExitHotspotView" },
                              //   "*"
                              // );
                              // // iframeRef.current.contentWindow.postMessage(
                              // //   { func: "MoveToExterior" },
                              // //   "*"
                              // // );
                              // // setselectedView("Exterior");
                              // sethotspotOption(null);
                              // dispatch({
                              //   type: actionTypes.SET_INSIDE_VIEW,
                              //   value: false,
                              //   id: null,
                              // });
                              sendButtonClickEvent(
                                "back_to_hotspotlist_button"
                              );
                              setisFeaturesEnabled(true);
                            }
                          }}
                        >
                          <BackIconNew />
                        </div>
                      </div>
                    </>
                  )
                ) : null}
                {isFeaturesEnabled ? null : hotspotOption === null ? (
                  enableDescription ? (
                    <ProductHighlights
                      setmodalContent={setmodalContent}
                      setenablemodal={setenablemodal}
                      setenableDescription={setenableDescription}
                      enableDescription={enableDescription}
                    />
                  ) : isHighlightEnabled ? (
                    <>
                      <div className="imageSwiper flex flex-1 flex-col overflow-y-scroll">
                        <div
                          className={`transition duration-150 ease-out sm:hidden sm:bottom-8 sm:w-[50%] sm:self-center sm:bg-[rgba(0,0,0,0.6)] sm:rounded-[10px] ${
                            isSidebarEnabled ? "sm:mr-[25%]" : ""
                          }`}
                        >
                          <div className="flex flex-1">
                            {translation.highlightContent &&
                              translation.highlightContent.map((hContent) => {
                                return (
                                  <div className="swiperSlideContainer flex flex-col flex-1 justify-center items-start p-4 sm:mb-4">
                                    {translation.highlightTitle && (
                                      <p className="text-black text-[18px] font-bold mb-[10px]">
                                        {translation.highlightTitle}
                                      </p>
                                    )}
                                    <p className="text-black text-[14px] text-left">
                                      {hContent.text}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      {animationLoaded && (
                        <div
                          className="sm:hidden flex justify-end px-8 py-1 cursor-pointer hover:underline"
                          onClick={() => {
                            sendButtonClickEvent("product_highlights_button");
                            setenableDescription(!enableDescription);
                          }}
                        >
                          <p className="text-[14px] text-[#E1251B]">
                            {translation.descriptionTitle}
                          </p>
                          <p className="text-[14px] ml-2 text-[#E1251B]">
                            {">>"}
                          </p>
                        </div>
                      )}
                    </>
                  ) : null
                ) : null}
              </div>
              <BottomTabBarMenu
                animationLoaded={animationLoaded}
                enabled={isIntroEnabled || isEnabledBottomTabs}
                openMenu={(value = true) => {
                  setisEnabledBottomTabs(value);
                }}
                tabs={
                  mobileCheck()
                    ? [
                        isInsideHotspotView
                          ? {
                              image: <ExitViewIcon />,
                              enableRightArrow: false,
                              id: "intext",
                              xflow: "left",
                              onclick: () => {
                                sendButtonClickEvent("exit_360_menu_button");
                                sethotspotOption(null);
                                iframeRef.current.contentWindow.postMessage(
                                  { func: "ExitHotspotView" },
                                  "*"
                                );

                                if (selectedView === "Exterior") {
                                  iframeRef.current.contentWindow.postMessage(
                                    { func: "MoveToExterior" },
                                    "*"
                                  );
                                } else if (selectedView === "Interior") {
                                  iframeRef.current.contentWindow.postMessage(
                                    { func: "MoveToInterior" },
                                    "*"
                                  );
                                }
                                dispatch({
                                  type: actionTypes.SET_INSIDE_VIEW,
                                  value: false,
                                  id: null,
                                });
                                setTimeout(() => {
                                  if (!isHotspotEnabled) {
                                    sendMessageToIframe("DisableAllHotspots");
                                    setIsHotspotEnabled(false);
                                  } else {
                                    sendMessageToIframe("EnableAllHotspots");
                                    setIsHotspotEnabled(true);
                                  }
                                }, 850);
                              },
                              selected: selectedView,
                              menu: [],
                              isInsideHotspotView: isInsideHotspotView,
                            }
                          : {
                              image: <TabIcon1 />,
                              enableRightArrow: false,
                              id: "intext",
                              xflow: "left",
                              onclick: () => {
                                sendButtonClickEvent("view_change_menu_button");
                              },
                              selected: selectedView,
                              menu: [
                                {
                                  text: "Exterior",
                                  onclick: () => {
                                    setselectedView("Exterior");
                                    sendMessageToIframe("MoveToExterior");
                                    sendButtonClickEvent("exterior_button");
                                    if (!isInsideHotspotView) {
                                      setTimeout(() => {
                                        if (!isHotspotEnabled) {
                                          sendMessageToIframe(
                                            "DisableAllHotspots"
                                          );
                                          setIsHotspotEnabled(false);
                                        } else {
                                          sendMessageToIframe(
                                            "EnableAllHotspots"
                                          );
                                          setIsHotspotEnabled(true);
                                        }
                                      }, 850);
                                    }
                                  },
                                },
                                {
                                  text: "Interior",
                                  onclick: () => {
                                    setselectedView("Interior");
                                    sendMessageToIframe("MoveToInterior");
                                    sendButtonClickEvent("interior_button");
                                    if (!isInsideHotspotView) {
                                      setTimeout(() => {
                                        if (!isHotspotEnabled) {
                                          sendMessageToIframe(
                                            "DisableAllHotspots"
                                          );
                                          setIsHotspotEnabled(false);
                                        } else {
                                          sendMessageToIframe(
                                            "EnableAllHotspots"
                                          );
                                          setIsHotspotEnabled(true);
                                        }
                                      }, 850);
                                    }
                                  },
                                },
                              ],
                            },
                        {
                          image: <TabIcon2 />,
                          enableRightArrow: false,
                          enabled: isHotspotEnabled,
                          onclick: () => {
                            if (!isInsideHotspotView) {
                              if (isHotspotEnabled) {
                                sendMessageToIframe("DisableAllHotspots");
                                setIsHotspotEnabled(false);
                                sendButtonClickEvent(
                                  "toggle_hotspot_menu_button",
                                  { enabled: false }
                                );
                              } else {
                                sendMessageToIframe("EnableAllHotspots");
                                setIsHotspotEnabled(true);
                                sendButtonClickEvent(
                                  "toggle_hotspot_menu_button",
                                  { enabled: true }
                                );
                              }
                            } else {
                              if (isHotspotEnabled) {
                                setIsHotspotEnabled(false);
                                sendButtonClickEvent(
                                  "toggle_hotspot_menu_button",
                                  { enabled: false }
                                );
                              } else {
                                setIsHotspotEnabled(true);
                                sendButtonClickEvent(
                                  "toggle_hotspot_menu_button",
                                  { enabled: true }
                                );
                              }
                            }
                          },
                          menu: [],
                        },
                        {
                          image: <TabIcon3 />,
                          enableRightArrow: false,
                          onclick: () => {
                            sendButtonClickEvent("hotspot_menu_button");
                            setisFeaturesEnabled(!isFeaturesEnabled);
                          },
                          id: "hotspotmenu",
                          menu: [],
                          hotspotOption: hotspotOption,
                          enabled:
                            isFeaturesEnabled ||
                            (hotspotOption !== null &&
                              isFeaturesEnabled !== null),
                        },
                        {
                          image: <TabIcon4 />,
                          enableRightArrow: true,
                          id: "support",
                          onclick: () => {
                            sendButtonClickEvent("contact_menu_button");
                          },
                          menu: [
                            {
                              text: translation.contactUs,
                              onclick: () => {
                                sendButtonClickEvent("contact_us_button");
                                window.open(
                                  "https://www.ingersollrand.com/modals/contact",
                                  "_blank"
                                );
                              },
                            },
                            {
                              text: translation.requestAQuote,
                              onclick: () => {
                                sendButtonClickEvent("request_quote_button");
                                window.open(
                                  "https://www.ingersollrand.com/modals/aftermarket",
                                  "_blank"
                                );
                              },
                            },
                            {
                              text: translation.requestSupport,
                              onclick: () => {
                                sendButtonClickEvent("request_support_button");
                                window.open(
                                  "https://www.ingersollrand.com/modals/request-a-quote",
                                  "_blank"
                                );
                              },
                            },
                          ],
                        },
                      ]
                    : [
                        {
                          image: <TabIcon2 />,
                          enableRightArrow: false,
                          xflow: "left",
                          enabled: checkIfMobileAndIntroEnabled()
                            ? false
                            : isHotspotEnabled,
                          onclick: () => {
                            if (!isInsideHotspotView) {
                              if (isHotspotEnabled) {
                                sendMessageToIframe("DisableAllHotspots");
                                setIsHotspotEnabled(false);
                                sendButtonClickEvent(
                                  "toggle_hotspot_menu_button",
                                  { enabled: false }
                                );
                              } else {
                                sendMessageToIframe("EnableAllHotspots");
                                setIsHotspotEnabled(true);
                                sendButtonClickEvent(
                                  "toggle_hotspot_menu_button",
                                  { enabled: true }
                                );
                              }
                            } else {
                              if (isHotspotEnabled) {
                                setIsHotspotEnabled(false);
                                sendButtonClickEvent(
                                  "toggle_hotspot_menu_button",
                                  { enabled: false }
                                );
                              } else {
                                setIsHotspotEnabled(true);
                                sendButtonClickEvent(
                                  "toggle_hotspot_menu_button",
                                  { enabled: true }
                                );
                              }
                            }
                          },
                          menu: [],
                        },
                        isInsideHotspotView
                          ? {
                              image: <ExitViewIcon />,
                              enableRightArrow: false,
                              onclick: () => {
                                sendButtonClickEvent("exit_360_menu_button");
                                iframeRef.current.contentWindow.postMessage(
                                  { func: "ExitHotspotView" },
                                  "*"
                                );
                                if (selectedView === "Exterior") {
                                  iframeRef.current.contentWindow.postMessage(
                                    { func: "MoveToExterior" },
                                    "*"
                                  );
                                } else if (selectedView === "Interior") {
                                  iframeRef.current.contentWindow.postMessage(
                                    { func: "MoveToInterior" },
                                    "*"
                                  );
                                }

                                setchangeSideMenuView(!changeSideMenuView);
                                dispatch({
                                  type: actionTypes.SET_INSIDE_VIEW,
                                  value: false,
                                  id: null,
                                });
                                setTimeout(() => {
                                  if (!isHotspotEnabled) {
                                    sendMessageToIframe("DisableAllHotspots");
                                    setIsHotspotEnabled(false);
                                  } else {
                                    sendMessageToIframe("EnableAllHotspots");
                                    setIsHotspotEnabled(true);
                                  }
                                }, 850);
                              },
                              menu: [],
                            }
                          : {
                              image: <TabIcon1 />,
                              enableRightArrow: false,
                              onclick: () => {
                                sendButtonClickEvent("view_change_menu_button");
                              },
                              selected: selectedView,
                              id: "rightMenu",
                              menu: [
                                {
                                  selected: selectedView === "Exterior",
                                  text: translation.exterior,
                                  onclick: () => {
                                    sendButtonClickEvent("exterior_button");
                                    setselectedView("Exterior");
                                    sendMessageToIframe("MoveToExterior");
                                    if (!isInsideHotspotView) {
                                      setTimeout(() => {
                                        if (!isHotspotEnabled) {
                                          sendMessageToIframe(
                                            "DisableAllHotspots"
                                          );
                                          setIsHotspotEnabled(false);
                                        } else {
                                          sendMessageToIframe(
                                            "EnableAllHotspots"
                                          );
                                          setIsHotspotEnabled(true);
                                        }
                                      }, 850);
                                    }
                                  },
                                },
                                {
                                  selected: selectedView === "Interior",
                                  text: translation.interior,
                                  onclick: () => {
                                    sendButtonClickEvent("interior_button");
                                    setselectedView("Interior");
                                    sendMessageToIframe("MoveToInterior");
                                    if (!isInsideHotspotView) {
                                      setTimeout(() => {
                                        if (!isHotspotEnabled) {
                                          sendMessageToIframe(
                                            "DisableAllHotspots"
                                          );
                                          setIsHotspotEnabled(false);
                                        } else {
                                          sendMessageToIframe(
                                            "EnableAllHotspots"
                                          );
                                          setIsHotspotEnabled(true);
                                        }
                                      }, 850);
                                    }
                                  },
                                },
                              ],
                            },
                        {
                          image: <TabIcon6 />,
                          enableRightArrow: false,
                          onclick: () => {
                            sendButtonClickEvent("fullscreen_menu_button");
                            toggleFullScreen();
                          },
                          menu: [],
                        },
                      ]
                }
              />
            </div>
          </div>
        </div>
        {animationLoaded && (
          <div
            className="hidden sm:flex sm:absolute right-[20px] top-[15px] w-[40px] z-[10] h-[40px] bg-[#E1251B] cursor-pointer justify-center items-center p-2 rounded-[10px] opacity-50 hover:opacity-100"
            onClick={() => {
              if (isSidebarEnabled === true) {
                iframeRef.current.contentWindow.postMessage(
                  { func: "ExitHotspotView" },
                  "*"
                );
                // iframeRef.current.contentWindow.postMessage(
                //   { func: "MoveToExterior" },
                //   "*"
                // );
                // setselectedView("Exterior");
              }
              sendButtonClickEvent("menu_button");
              setIsSidebarEnabled(!isSidebarEnabled);
            }}
          >
            <MenuIcon />
          </div>
        )}
        {animationLoaded && (
          <div
            className={`p-1 hidden sm:flex sm:absolute left-[20px] bottom-[15px] w-[40px] z-[10] h-[40px] bg-[#E1251B] cursor-pointer justify-center items-center rounded-[10px] opacity-50 hover:opacity-100`}
            onClick={() => {
              sendButtonClickEvent("info_button");
              setIsSidebarEnabled(true);
              setisInfoButtonClicked(true);
            }}
          >
            <div className="animate-zoom">
              <InfoIcon />
            </div>
          </div>
        )}

        {!loading && animationLoaded && (
          <DesktopLanguageButton
            isSidebarEnabled={isSidebarEnabled}
            isEnableLangButton={isEnableLangButton}
            menu={menu}
          />
        )}
        <SideMenu
          setIsHotspotEnabled={setIsHotspotEnabled}
          clickedHotspot={clickedHotspot}
          enabled={isSidebarEnabled}
          scenes={scenes.scenes}
          iframeRef={iframeRef}
          setselectedView={setselectedView}
          closeSideMenu={closeSideMenu}
          isInfoButtonClicked={isInfoButtonClicked}
          setisInfoButtonClicked={setisInfoButtonClicked}
          openTabBarMenu={(value = true) => {
            setisEnabledBottomTabs(value);
          }}
          selectedView={selectedView}
          changeSideMenuView={changeSideMenuView}
          sendMessageToIframe={sendMessageToIframe}
          isHotspotEnabled={isHotspotEnabled}
        />
        {enablemodal && (
          <HighlightModal
            setenablemodal={setenablemodal}
            modalContent={modalContent}
          />
        )}
      </div>
    </>
  );
}

export default Home;
