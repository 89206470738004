import contentEn from "./data/content_en.json";
import contentEs from "./data/content_es.json";
import contentPt from "./data/content_pt.json";

export const initialState = {
  lang: {
    label: "English",
    key: "en",
  },
  translation: contentEn,
  isInsideHotspotView: false,
  hotspotViewSelected: null,
  noLanguageChage: false,
};

function getContent(langKey) {
  switch (langKey) {
    case "en":
      return contentEn;
    case "es":
      return contentEs;
    case "pt":
      return contentPt;
    default:
      break;
  }
}

export const actionTypes = {
  SET_LANGUAGE: "SET_LANGUAGE",
  SET_INSIDE_VIEW: "SET_INSIDE_VIEW",
  SET_NO_LANGUAGE_CHANGE: "SET_NO_LANGUAGE_CHANGE",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      let content = getContent(action.lang.key);
      return {
        ...state,
        lang: action.lang,
        translation: content,
      };

    case actionTypes.SET_INSIDE_VIEW:
      return {
        ...state,
        isInsideHotspotView: action.value,
        hotspotViewSelected: action.id,
      };

    case actionTypes.SET_NO_LANGUAGE_CHANGE:
      return {
        ...state,
        noLanguageChage: action.value,
      };

    default:
      return state;
  }
};

export default reducer;
