import React from "react";
import { ReactComponent as AddIcon } from "../assets/images/addIcon.svg";
import { useStateValue } from "../StateProvider";
import { actionTypes } from "../reducer";
import Switch from "react-switch";
import { BackIconNew } from "../assets/images";
import { sendButtonClickEvent } from "../GoogleAnalytics";

const FeatureLists = ({
  menu,
  iframeRef,
  selectedView,
  setselectedView,
  reset,
  hotspotOption,
  isHotspotEnabled,
  sendMessageToIframe,
  setIsHotspotEnabled,
}) => {
  const [{ translation, isInsideHotspotView, hotspotViewSelected }, dispatch] =
    useStateValue();
  return (
    <>
      <div className="p-4 bg-[#fff] sm:hidden border-b-[1px] border-[#54555A]">
        <p className="text-[14px] text-[#E1251B] font-bold text-left">
          {translation.featuresTitle}
        </p>
      </div>
      <div className="imageSwiper z-0 flex flex-1 flex-col overflow-y-scroll px-1 pb-2">
        <div class="">
          {menu &&
            menu.map((men, mindex) => {
              return (
                <div
                  key={mindex}
                  onClick={() => {
                    sendButtonClickEvent("hotspot_element", {
                      element: men.heading,
                      from: "mobileview",
                    });
                    men.onclick();
                  }}
                  className={`flex z-[1] w-full bg-white justify-start items-center pl-[15px] pr-[30px] py-[10px] cursor-pointer border border-2 ${
                    hotspotOption === men.id || hotspotViewSelected === men.id
                      ? "border-[#E1251B]"
                      : "border-[#c8c8c8]"
                  } mt-2 rounded-[10px] relative`}
                >
                  <div>
                    <p className="font-bold text-black text-[12px] text-left">
                      {men.heading ? men.heading : null}
                    </p>
                    <p className="text-black text-[12px] text-left line-limit">
                      {men.description}
                    </p>
                  </div>
                  <div className="rounded-full absolute right-1 top-1 p-1 bg-[#d9d9d9] aspect-square w-[25px] h-[25px] flex justify-center items-center">
                    <AddIcon />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="border-t-[1px] border-[#54555A] px-[25px] py-2 flex justify-center items-center">
        <Switch
          onChange={(nextChecked) => {
            if (!isInsideHotspotView) {
              if (selectedView === "Exterior") {
                iframeRef.current.contentWindow.postMessage(
                  { func: "MoveToInterior" },
                  "*"
                );
              }
              if (selectedView === "Interior") {
                iframeRef.current.contentWindow.postMessage(
                  { func: "MoveToExterior" },
                  "*"
                );
              }
            }
            if (selectedView === "Exterior") {
              setselectedView("Interior");
              sendButtonClickEvent("view_change_switch_button", {
                option: "interior",
              });
            }
            if (selectedView === "Interior") {
              setselectedView("Exterior");
              sendButtonClickEvent("view_change_switch_button", {
                option: "exterior",
              });
            }
            if (!isInsideHotspotView) {
              setTimeout(() => {
                if (!isHotspotEnabled) {
                  sendMessageToIframe("DisableAllHotspots");
                  setIsHotspotEnabled(false);
                } else {
                  sendMessageToIframe("EnableAllHotspots");
                  setIsHotspotEnabled(true);
                }
              }, 850);
            }
          }}
          checked={selectedView === "Exterior"}
          className="react-switch"
          width={160}
          height={38}
          offColor="#E1251B"
          onColor="#E1251B"
          uncheckedIcon={
            <div className="flex flex-1 justify-center items-center">
              <p className="text-[14px] font-bold text-white">
                {translation.interior}
              </p>
            </div>
          }
          checkedIcon={
            <div className="flex flex-1 justify-center items-center">
              <p className="text-[14px] font-bold text-white">
                {translation.exterior}
              </p>
            </div>
          }
        />
        <div
          className={`absolute right-[25px] w-[30px] h-[30px] flex justify-center items-center cursor-pointer rounded-full bg-[#E1251B]`}
          onClick={() => {
            sendButtonClickEvent("back_menu_button");
            reset();
          }}
        >
          <BackIconNew />
        </div>
      </div>
    </>
  );
};

export default FeatureLists;
