import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./routes/home";
import Splash from "./routes/splash";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { useEffect, useRef, useState } from "react";
import rotate from "./assets/images/rotate.png";
import { mobileCheck, tabletCheck } from "./functions";
import { irLogo } from "./assets/images";
import useGoogleAnalytics from "./GoogleAnalytics";

const App = (props) => {
  const containerRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(false);
  useGoogleAnalytics();

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  const checkWindowOrientation = () => {
    setIsLandscape(window.innerWidth > window.innerHeight);
  };

  useEffect(() => {
    checkWindowOrientation();
    window.addEventListener("resize", checkWindowOrientation);

    return () => {
      window.removeEventListener("resize", checkWindowOrientation);
    };
  }, []);

  if ((mobileCheck() && isLandscape) || (tabletCheck() && !isLandscape)) {
    return (
      <div className="w-full flex-col h-[100dvh] flex flex-1 justify-center items-center">
        <img src={irLogo} className="sm:w-[150px] w-[60px]" />
        <img src={rotate} className="w-[50px] mt-6" />
        <p className="mt-4 text-[#54555A]">
          Please rotate to {isLandscape ? "portrait" : "landscape"}
        </p>
      </div>
    );
  }

  return (
    <div ref={containerRef}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
